import Flickity from 'Flickity'

function DOMready(callback) {
  document.readyState !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const carousel = document.querySelector('.reviews__carousel')

  if (carousel !== null) {
    // eslint-disable-next-line no-unused-vars
    const flkty = new Flickity(carousel, {
      // options
      cellAlign: 'center',
      prevNextButtons: false,
      wrapAround: true,
      // contain: true,
    })
  }
})
